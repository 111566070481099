import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 4×4\\@80% 1RM`}</p>
    <p>{`Single Leg Bent Leg Bridge 4×4/leg`}</p>
    <p>{`then,`}</p>
    <p>{`25-Burpees`}</p>
    <p>{`800M Run`}</p>
    <p>{`15-Power Cleans (135/95)(RX+ 165/110)`}</p>
    <p>{`20-Burpees`}</p>
    <p>{`400M Run`}</p>
    <p>{`10-Power Cleans`}</p>
    <p>{`15-Burpees`}</p>
    <p>{`200M Run`}</p>
    <p>{`5-Power Cleans`}</p>
    <p>{`for time.`}</p>
    <ul>
      <li parentName="ul">{`*`}<strong parentName="li">{`This Saturday will be our free Partner WOD so bring a friend!
Classes are at 9:00 & 10:15. If you aren’t a member but would like
to attend email us at:`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Daniel\\@crossfittheville.org.`}</strong></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      